import { type Country, siteConfig } from '@seek/melways-sites';

import type { Site } from './types';

export const isDevEnvironment = (env: string) => env === 'dev';
export const isProdEnvironment = (env: string) => env === 'production';

export const getQueryString = (n: string) => {
  if (typeof window !== 'undefined' && window.location) {
    const half = window.location.search.split(`${n}=`)[1];
    return typeof half !== 'undefined'
      ? decodeURIComponent(half.split('&')[0])
      : '';
  }
  return '';
};

// encode SVG string for css background rendering
export const encodeSvg = (svg: string) =>
  `url('data:image/svg+xml;utf8,${svg.replace(/\#/g, '%23')}')`;

export const isProduction = () => process.env.NODE_ENV === 'production';

// Easy console logging that wont show up on Production env
export const logHandler = (data: any) => {
  if (!isProduction()) {
    console.log(data); // eslint-disable-line no-console
  }
};

export const getCountryFromSite = (site: Site): Country =>
  siteConfig[site].classification.country;
