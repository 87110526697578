import { Box, Stack, ContentBlock } from 'braid-design-system';

import { encodeSvg } from 'src/shared/helpers';

import ConnectingSvg from './Connecting.svg';
import FixingSvg from './Fixing2.svg';
import NoMatchSvg from './NoMatch.svg';
import NothingSvg from './Nothing.svg';

type ImageType = 'Nothing' | 'Fixing' | 'Connecting' | 'NoMatch';

const ImageOptions = {
  Nothing: NothingSvg,
  Fixing: FixingSvg,
  Connecting: ConnectingSvg,
  NoMatch: NoMatchSvg,
};
interface Props {
  children: React.ReactNode;
  image?: ImageType;
}

export const ErrorWrapper = ({ children, image = 'Nothing' }: Props) => (
  <ContentBlock width="small">
    <Box paddingY={['none', 'xxlarge']}>
      <Box
        background="surface"
        paddingY={['xxlarge', 'xlarge']}
        paddingX="gutter"
      >
        <Stack space={['large', 'xlarge']} align="center">
          <Box
            style={{
              width: image === 'Fixing' ? '254px' : '128px',
              height: '128px',
              background: encodeSvg(ImageOptions[image]),
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          />
          <Box>{children}</Box>
        </Stack>
      </Box>
    </Box>
  </ContentBlock>
);
